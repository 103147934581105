<div class="sidenav-right">
  <mat-card class="dashboard-card" *ngFor="let item of data">
    <div class="row">
      <div [ngClass]="{'col-3': item.image, 'col-1': item.hasOwnProperty('avatar')}" *ngIf="image">
        <div mat-card-avatar class="avatar-img" *ngIf="item.hasOwnProperty('avatar')"
             [ngStyle]="{'background-image': avatarImage(item.avatar)}"></div>
        <img *ngIf="item.image" [src]="item.image" [alt]="item.name ? item.name : item.title">
      </div>
      <div
        [ngClass]="{'col-7': item.image || item.hasOwnProperty('avatar'), 'col-9': !item.image && !item.hasOwnProperty('avatar')}">
        <mat-card-title [ngClass]="{'mt-2': !item.image || !item.hasOwnProperty('avatar')}">
          {{item.name ? item.role ? item.name + (item.role == 'admin' ? ' (administrador)' : ' (usuario)') : item.name + ' (usuario) ' :
          item.title + (item.visibility ? item.visibility === 'draft' ? '(Borrador)' : '' : '')}}
        </mat-card-title>
        <mat-card-content *ngIf="item.content">
          <p [innerHTML]="(_parseHtml(item.content) | slice: 0: 200)+'...'"></p>
        </mat-card-content>
      </div>
      <div class="text-right align-self-end"
           [ngClass]="{'col-2': item.image, 'col-4': item.hasOwnProperty('email'), 'col-3': !item.image && !item.hasOwnProperty('avatar')}">
        <mat-chip-list class="float-right mb-3" aria-label="Fish selection" [multiple]="false" *ngIf="favorite">
          <mat-chip class="favorites" [disableRipple]="true">
            <mat-icon class="mr-1">favorite</mat-icon>
            {{item.score ? item.score : 0}}
          </mat-chip>
        </mat-chip-list>
        <p class="h6" *ngIf="item.email">{{item.email}}</p>
        <button mat-button [ngClass]="{'mt-3': item.image || item.hasOwnProperty('avatar')}" color="primary"
                aria-label="edit benefit" (click)="editData.emit(item)">
          Editar
          <mat-icon>mode_edit</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
</div>

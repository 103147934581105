<div class="loading-wrapper">
  <div class="loader">
    <!-- <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div> -->
    <mat-spinner [diameter]="50" color="warn"></mat-spinner>
  </div>
</div>

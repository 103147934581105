import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Benefit } from '../../models/benefit';
import { News } from '../../models/news';
import { User } from '../../models/user';
import { Survey } from '../../models/surveys';
import { Documents } from '../../models/documents';
import { Video } from '../../models/video';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent implements OnInit {
  @Input() data: Benefit[] | News[] | User[] | Survey[] | Documents[] | Video[];
  @Input() favorite = false;
  @Input() image = true;
  @Output() editData: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  _parseHtml(value: string): string {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    if (doc.querySelector('p')) {
      return doc.querySelector('p').textContent;
    }
    return value;
  }

  avatarImage(avatar): string {
    return avatar
      ? `url(${avatar})`
      : 'url(/assets/images/user-placeholder.png)';
  }
}

export class User {
  constructor(
    public uid: string,
    public email: string,
    public name: string,
    public avatar: string = null,
    public pushToken: string = null,
    public role: string = null,
    public id: string = null
  ) {}
}

export interface User {
  uid: string;
  id: string;
  email: string;
  name: string;
  avatar: string;
  pushToken: string;
  role: string;
}

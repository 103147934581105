import { Injectable } from '@angular/core';

@Injectable()
export class Utils {
  static _convertToSlug(str: string): string {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeeiiiiooooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  public static _parseHtml(value: string): string {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    return doc.querySelector('p').textContent;
  }

  public static getUrlName(url: string, type: number): string {
    const splitType = type === 1 ? 2 : 3;
    return url.split('%2F')[splitType] ? url.split('%2F')[splitType].split('?')[0] : url.split('%2F')[1].split('?')[0];
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { MessagingService } from './services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'admin-panel';

  constructor(
    private authService: AuthService,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated) {
      this.messagingService.requestPermission();
      this.messagingService.receiveMessage();
    }
  }
}

<h1 mat-dialog-title>Seleccione Ubicacion del beneficio</h1>
<agm-map [latitude]='lat' [longitude]='long' [zoom]="12" (mapClick)="mapClicked($event)">
  <agm-marker
    *ngFor="let m of markers; let i = index"

    [latitude]="m.lat"
    [longitude]="m.lng"
    [label]="m.label"
    [markerDraggable]="m.draggable"
  >

    <agm-info-window>
      <strong>{{m.address}}</strong>
    </agm-info-window>

  </agm-marker>
</agm-map>
<mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="onNoClick()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="onAddLocation()" [disabled]="!markers || markers.length === 0">
    Aceptar
  </button>
</mat-dialog-actions>

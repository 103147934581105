<h1 mat-dialog-title>¿Desea eliminar {{ data.name }}?</h1>
<div mat-dialog-content>
  Después de aceptar, los datos no podrán ser recuperados.
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>
    Aceptar
  </button>
</mat-dialog-actions>

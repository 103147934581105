import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private httpClient: HttpClient
  ) {
    this.angularFireMessaging.messages.subscribe(
      (messages: AngularFireMessaging) => {
        messages.onMessage = messages.onMessage.bind(messages);
        messages.onTokenRefresh = messages.onTokenRefresh.bind(messages);
      }
    );
  }

  sendNotification(body: any): Observable<any> {
    return this.httpClient.post(
      `${environment.functionsUrl}sendPushNotifications`,
      body
    );
  }

  requestPermission(): void {
    this.angularFireMessaging.requestToken.subscribe(
      () => null,
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage(): void {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log('new message received. ', payload);
      this.currentMessage.next(payload);
    });
  }
}

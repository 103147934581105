<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-4" *ngIf="!instanceOf('Survey') && !instanceOf('Video')">
        <div class="avatar-upload mb-3">
          <div class="avatar-edit">
            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" (change)="readURL($event)"
                   #benefitImageFile/>
            <label for="imageUpload">
              <button mat-icon-button color="primary" type="button" (click)="benefitImageFile.click()">
                <mat-icon>mode_edit</mat-icon>
              </button>
            </label>
          </div>
          <div class="avatar-preview">
            <div #imagePreview id="imagePreview"
                 [ngStyle]="{'background-image': buildImagePreview()}">
            </div>
          </div>
        </div>
        <p>Recomendamos una resolución de 1200x500 para un resultado óptimo en los dispositivos</p>
        <button *ngIf="instanceOf('Benefit')"
          class="mb-3"
          mat-raised-button
          color="primary"
          (click)="onSelectLocation()"
          type="button"
        >
          Seleccionar ubicacion
          <mat-icon>location_on</mat-icon>
        </button>

        <mat-hint *ngIf="instanceOf('Benefit')">
          NOTA: El beneficio tendrá como categoría por defecto la seleccionada en el menú izquierdo. <br>
          Si desea agregar más categoría seleccione en el siguiente campo.
        </mat-hint>

        <mat-form-field class="w-100" appearance="outline" *ngIf="instanceOf('Benefit')">
          <mat-label>Categorías adicionales</mat-label>
          <mat-select formControlName="benefitCategory" multiple>
            <mat-select-trigger>
              <mat-chip-list>
                <mat-chip *ngFor="let category of categoriesForm.value"
                          [removable]="true" (removed)="removeChips(category, 'cate')">
                  {{showCategoryName(category)}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </mat-select-trigger>
            <mat-option *ngFor="let category of data.categories" [value]="category.id">{{category.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div [ngClass]="{'col-12': instanceOf('Survey') || instanceOf('Video'), 'col-8': !instanceOf('Survey') && !instanceOf('Video')}">
        <mat-hint *ngIf="instanceOf('Survey')" class="py-3">Las preguntas de la encuesta se crean en <a href="https://workspace.google.com/google/forms" target="_blank">Google Form</a>
          una vez listas, en esta pantalla se le asigna el nombre a la encuesta y se coloca el link.
        </mat-hint>

        <div *ngFor="let control of data.form">
          <div [ngSwitch]="control.type">
            <mat-form-field class="w-100" appearance="outline" *ngSwitchCase="'text'">
              <mat-label>{{control.label}}</mat-label>
              <input [type]="control.type" [formControlName]="control.controlName" matInput [required]="control.required">
              <mat-icon matSuffix>{{control.icon}}</mat-icon>
            </mat-form-field>
            <div *ngSwitchCase="'description'">
              <angular-editor
                [formControlName]="control.controlName"
                [config]="editorConfig"
              ></angular-editor>
              <div class="mb-3"></div>
            </div>
            <div *ngSwitchCase="'file'">
              <mat-form-field class="w-100" appearance="outline" >
                <mat-label>{{control.label}}</mat-label>
                <ngx-mat-file-input (change)="onFileSelect($event, 'f', control.controlName)"></ngx-mat-file-input>
                <mat-icon matSuffix>{{control.icon}}</mat-icon>
              </mat-form-field>
              <mat-hint *ngIf="data.action === edit">
                {{control.value ? 'Nombre del archivo actual para descargar: '+fileName(control.value) : 'No hay archivo disponible para descargar'}}
              </mat-hint>
            </div>
            <div *ngSwitchCase="'video'">
              <mat-form-field class="w-100" appearance="outline" >
                <mat-label>{{control.label}}</mat-label>
                <ngx-mat-file-input accept=".mp4, .avi" (change)="onFileSelect($event, 'f', control.controlName)"></ngx-mat-file-input>
                <mat-icon matSuffix>{{control.icon}}</mat-icon>
              </mat-form-field>
              <mat-hint *ngIf="data.action === edit">
                {{control.value ? 'Nombre del archivo actual: '+fileName(control.value) : 'No hay archivo disponible'}}
              </mat-hint>
            </div>
            <mat-form-field color="accent" class="w-100" appearance="outline" *ngSwitchCase="'pill'">
              <mat-label>{{control.label}}</mat-label>
              <mat-chip-list #docList [formControlName]="control.controlName">
                <mat-chip *ngFor="let doc of documents.value" [selectable]="selectable"
                          [removable]="removable" (removed)="removeChips(doc, 'doc')">
                  {{doc}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Nuevo documento..."
                       [matChipInputFor]="docList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       (matChipInputTokenEnd)="addChips($event, 'doc')"
                >
              </mat-chip-list>
            </mat-form-field>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<mat-dialog-actions align="end">
  <app-action-buttons [deleteIf]="data.action === edit" (deleteAction)="onClick($event)"
                      (cancelAction)="onClick($event)" [cancelIf]="true"
                      [saveDisabled]="form.invalid" (draftAction)="onClick($event)"
                      (saveAction)="onClick($event)"></app-action-buttons>
</mat-dialog-actions>

import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormTemplate } from '../../models/form';
import { Actions } from '../../models/actions';
import { Survey } from '../../models/surveys';
import { News } from '../../models/news';
import { Documents } from '../../models/documents';
import { Benefit } from '../../models/benefit';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Globals } from '../../models/globals';
import { MapsDialogComponent } from '../maps-dialog/maps-dialog.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Category } from '../../models/category';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
})
export class FormDialogComponent implements OnInit {
  controls: string[] = [];
  form: FormGroup;
  edit: Actions = Actions.Edit;
  editorConfig: AngularEditorConfig = Globals.editorConfig;
  removable = true;
  selectable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('imagePreview') imagePreviewRef: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      form: FormTemplate[];
      title: string;
      action: Actions;
      type: 'Survey' | 'News' | 'Documents' | 'Benefit' | 'Video';
      categories: Category[];
    },
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const group = {};
    this.data.form.map((item) => {
      group[item.controlName] = item.required
        ? [item.value, Validators.required]
        : [item.value];
    });

    this.form = this.fb.group(group);
  }

  onClick(action: Actions): void {
    if (action === Actions.Add || action === Actions.Edit) {
      if (this.form.invalid) {
        return;
      }
    }
    this.dialogRef.close({ action, data: this.form.value });
  }

  instanceOf(type: string): boolean {
    return (
      this.data.type === type ||
      this.data.type === type ||
      this.data.type === type ||
      this.data.type === type
    );
  }

  readURL(input): void {
    if (input.target.files && input.target.files[0]) {
      if (this.instanceOf('Documents')) {
        this.onFileSelect(input, 'i', 'docImage');
      } else if (this.instanceOf('News')) {
        this.onFileSelect(input, 'i', 'newImage');
      } else if (this.instanceOf('Benefit')) {
        this.onFileSelect(input, 'i', 'benefitImage');
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreviewRef.nativeElement.style.backgroundImage = `url(${e.target.result})`;
      };
      reader.readAsDataURL(input.target.files[0]);
    }
  }

  onFileSelect(event: any, type: string, control: string): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (type === 'f') {
        this.form.controls[control].setValue(file);
      } else {
        this.form.controls[control].setValue(file);
      }
    }
  }

  buildImagePreview(): string {
    if (this.instanceOf('Documents')) {
      return this.data.action === this.edit
        ? `url(${
            this.data.form.find((x) => x.controlName === 'docImage').value
          })`
        : 'url(assets/images/docs.png)';
    } else if (this.instanceOf('News')) {
      return this.data.action === this.edit
        ? `url(${
            this.data.form.find((x) => x.controlName === 'newImage').value
          })`
        : 'url(assets/images/news.png)';
    } else if (this.instanceOf('Benefit')) {
      return this.data.action === this.edit
        ? `url(${
            this.data.form.find((x) => x.controlName === 'benefitImage').value
          })`
        : 'url(assets/images/benefits.png)';
    } else {
      return '';
    }
  }

  fileName(value: string): string {
    return value ? Utils.getUrlName(value, 1) : '';
  }

  onSelectLocation(): void {
    const dialogRef = this.dialog.open(MapsDialogComponent, {
      data: {
        marker:
          this.data.action === this.edit
            ? {
                lat: this.form.get('benefitLat').value,
                lng: this.form.get('benefitLon').value,
                draggable: false,
                address: this.form.get('benefitAddress').value,
              }
            : null,
      },
      width: '650px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.form.patchValue({
          benefitLat: result.lat,
          benefitLon: result.lng,
          benefitAddress: result.address,
        });
      }
    });
  }

  get documents(): AbstractControl {
    return this.form.get('benefitArchive');
  }

  get categoriesForm(): AbstractControl {
    return this.form.get('benefitCategory');
  }

  addChips(event: MatChipInputEvent, type: string): void {
    const input = event.input;
    const value = event.value;

    if (type === 'doc') {
      if ((value || '').trim()) {
        this.documents.setValue([...this.documents.value, value.trim()]);
        this.documents.updateValueAndValidity();
      }
    } else if (type === 'cate') {
      if ((value || '').trim()) {
        this.categoriesForm.setValue([...this.documents.value, value.trim()]);
        this.categoriesForm.updateValueAndValidity();
      }
    }
    if (input) {
      input.value = '';
    }
  }

  removeChips(fruit: string, type: string): void {
    if (type === 'doc') {
      const array = this.documents.value as string[];
      const index = array.indexOf(fruit);
      if (index >= 0) {
        array.splice(index, 1);
        this.documents.setValue([...array]);
        this.documents.updateValueAndValidity();
      }
    } else if (type === 'cate') {
      const array = this.categoriesForm.value as string[];
      const index = array.indexOf(fruit);
      if (index >= 0) {
        array.splice(index, 1);
        this.categoriesForm.setValue([...array]);
        this.categoriesForm.updateValueAndValidity();
      }
    }
  }

  showCategoryName(id: string): string {
    return this.data.categories.find((x) => x.id === id).name;
  }
}

import { NgModule } from '@angular/core';
import { MapsDialogComponent } from './maps-dialog.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [MapsDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebaseConfig.apiKey,
      libraries: ['places'],
    }),
  ],
})
export class MapsDialogModule {}

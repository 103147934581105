import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'benefits',
        loadChildren: () =>
          import('./pages/benefits/benefits.module').then(
            (m) => m.BenefitsModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'chat-room',
        loadChildren: () =>
          import('./pages/chat-room/chat-room.module').then(
            (m) => m.ChatRoomModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./pages/news/news.module').then((m) => m.NewsModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'surveys',
        loadChildren: () =>
          import('./pages/surveys/surveys.module').then((m) => m.SurveysModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'configurations',
        loadChildren: () =>
          import('./pages/configs/configs.module').then((m) => m.ConfigsModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'documents',
        loadChildren: () =>
          import('./pages/documents/documents.module').then(
            (m) => m.DocumentsModule
          ),
      },
      {
        path: 'videos',
        loadChildren: () =>
          import('./pages/videos/videos.module').then((m) => m.VideosModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Category } from '../../models/category';
import iconsData from '../../../assets/data/material-icons.json';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  selectedIcon: string;
  active = false;
  form: FormGroup;
  icons = iconsData.filter(
    (icon) =>
      !icon.icon.includes('rounded') &&
      !icon.icon.includes('sharp') &&
      !icon.icon.includes('outlined')
  );
  filterLabelControl = new FormControl('regular');
  constructor(
    public dialogRef: MatDialogRef<CategoryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name: string;
      editing: boolean;
      editingData: Category;
    },
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [
        this.data.editing ? this.data.editingData.name : '',
        Validators.required,
      ],
      icon: [
        this.data.editing ? this.data.editingData.image : { icon: '', id: 0 },
        Validators.required,
      ],
      filter: this.filterLabelControl,
      visibility: 'draft',
    });
    if (this.data.editing) {
      this.selectedIcon = this.data.editingData.image.icon;
    }
    this.form.get('filter').valueChanges.subscribe((value) => {
      if (value === 'regular') {
        this.icons = iconsData.filter(
          (icon) =>
            !icon.icon.includes('rounded') &&
            !icon.icon.includes('sharp') &&
            !icon.icon.includes('outlined')
        );
      } else {
        this.icons = iconsData.filter((icon) => icon.icon.includes(value));
      }
    });
  }

  onIconSelected(icon: { icon: string; id: number }): void {
    this.selectedIcon = icon.icon;
    this.form.patchValue({
      icon,
    });
  }

  onNoClick(remove?: boolean): void {
    this.dialogRef.close(remove);
  }

  onSaveCategory(visibility: string): void {
    this.form.patchValue({
      visibility,
    });
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}

<div class="toolbar-wrapper">
  <mat-toolbar class="container">
    <button mat-icon-button aria-label="Go back" (click)="onNavigate('dashboard')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <span>
      {{title}}
    </span>
    <span class="example-spacer"></span>
    <div class="w-100 text-center" *ngIf="secondTitle">
      <h6>
        {{secondTitle}}
      </h6>
    </div>
    <div *ngIf="button">
      <button
        mat-raised-button
        color="primary"
        (click)="onAdding.emit(true)"
        *ngIf="!adding"
      >
        {{buttonText}}
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</div>

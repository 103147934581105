import { BrowserModule } from '@angular/platform-browser';
import { isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { AsyncPipe, registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { IsLoadingModule } from '@service-work/is-loading';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { LayoutModule } from './layout/layout/layout.module';
import { MessagingService } from './services/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ShareModule } from './components/share.module';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {
  AngularFireRemoteConfigModule,
  SETTINGS,
} from '@angular/fire/remote-config';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import localeES from '@angular/common/locales/es';

registerLocaleData(localeES);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShareModule,
    HttpClientModule,
    IsLoadingModule,
    LayoutModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,
    AngularFireRemoteConfigModule,
    MatSnackBarModule,
  ],
  providers: [
    MessagingService,
    AsyncPipe,
    {
      provide: SETTINGS,
      useFactory: () =>
        isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {},
    },
    { provide: LOCALE_ID, useValue: 'es_ES' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

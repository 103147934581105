<button
  class="mr-3"
  mat-button
  (click)="deleteAction.emit(delete)"
  color="warn"
  type="button"
  *ngIf="deleteIf"
>
  Eliminar <mat-icon>delete</mat-icon>
</button>

<button
  class="mr-3"
  mat-button
  (click)="cancelAction.emit(cancel)"
  color="primary"
  type="reset"
  *ngIf="cancelIf"
>
  Cancelar <mat-icon>close</mat-icon>
</button>

<button
  class="mr-3"
  mat-raised-button
  (click)="draftAction.emit(draft)"
  color="accent"
  type="button"
  [disabled]="saveDisabled"
>
  Borrador <mat-icon>drive_file_rename_outline</mat-icon>
</button>

<button
  mat-raised-button
  color="primary"
  type="button"
  [disabled]="saveDisabled || onlyDraft"
  (click)="saveAction.emit(add)"
>
  Guardar <mat-icon>save</mat-icon>
</button>

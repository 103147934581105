export const environment = {
  production: false,
  functionsUrl: 'https://us-central1-bienestar-2c18b.cloudfunctions.net/',
  notificationChannel: 'notification_channel_dev',
  bucket: 'dev/',
  tables: {
    benefits: 'benefitsDev',
    categories: 'categoriesDev',
    chats: 'chatsDev',
    users: 'usersDev',
    events: 'eventsDev',
    news: 'newsDev',
    surveys: 'surveysDev',
    documents: 'documentsDev',
    videos: 'videosDev',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAzqvOGaz6Yy0ZZAH8_jLKE4mglcCxqc90',
    authDomain: 'bienestar-2c18b.firebaseapp.com',
    databaseURL: 'https://bienestar-2c18b.firebaseio.com',
    projectId: 'bienestar-2c18b',
    storageBucket: 'bienestar-2c18b.appspot.com',
    messagingSenderId: '946271723651',
    appId: '1:946271723651:web:ebbace295d5c02838dbbb6',
    measurementId: 'G-GL061W8W7J',
  },
};

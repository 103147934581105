<h1 mat-dialog-title>{{ data.name }} <mat-icon>{{selectedIcon}}</mat-icon> </h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input formControlName="name" matInput>
    </mat-form-field>
  </form>
  <div class="row mb-3">
    <div class="col-12">
      <label>Tipo de iconos</label>
      <br>
      <mat-radio-group [formControl]="filterLabelControl" color="primary">
        <mat-radio-button class="mr-3" value="regular">Regular</mat-radio-button>
        <mat-radio-button class="mr-3" value="rounded">Rounded</mat-radio-button>
        <mat-radio-button class="mr-3" value="sharp">Sharp</mat-radio-button>
        <mat-radio-button class="mr-3" value="outlined">Outlined</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button mat-icon-button *ngFor="let icon of icons" [color]="icon.icon === selectedIcon ? 'primary': 'accent'" (click)="onIconSelected(icon)">
        <mat-icon>{{icon.icon}}</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-button color="warn" type="button" (click)="onNoClick(true)" *ngIf="data.editing">Eliminar<mat-icon>delete</mat-icon></button>
  <button mat-button color="primary" type="reset" (click)="onNoClick()">Cancelar <mat-icon>close</mat-icon></button>
  <button mat-raised-button color="accent"  (click)="onSaveCategory('draft')" [disabled]="form.invalid">
    Borrador
    <mat-icon>drive_file_rename_outline</mat-icon>
  </button>
  <button mat-raised-button color="primary"  (click)="onSaveCategory('public')" [disabled]="form.invalid">
    Aceptar
    <mat-icon>save</mat-icon>
  </button>
</mat-dialog-actions>

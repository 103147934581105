<div style="background: var(--primary-color)">
  <mat-toolbar class="container" color="primary">
    <span style="cursor: pointer" (click)="onNavigate('dashboard')">
      <img class="img-fluid" src="assets/images/logo.svg" width="150px"
           alt="Logo">
    </span>
    <span class="example-spacer"></span>
    <span class="greetings mr-3">Buen día, {{currentUser?.name}}</span>
    <div mat-card-avatar class="avatar-img" [ngStyle]="{'background-image': avatarImage()}" [matMenuTriggerFor]="menu"></div>
    <mat-menu #menu="matMenu" xPosition="before">
      <div mat-card-avatar class="menu-img" [ngStyle]="{'background-image': avatarImage()}"></div>
      <div class="profile">
        <h4>{{currentUser?.name}}</h4>
        <span>{{currentUser?.email}}</span>
      </div>
      <mat-divider></mat-divider>
      <button class="exit" mat-menu-item aria-label="Logout button" (click)="logout()">
        <mat-icon color="primary">exit_to_app</mat-icon>
        <span>Cerrar sesion</span>
      </button>
    </mat-menu>
  </mat-toolbar>
</div>
<router-outlet></router-outlet>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { LoadingComponent } from './loading/loading.component';
import { DialogComponent } from './dialog/dialog.component';
import { MatTableModule } from '@angular/material/table';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { CategoryComponent } from './category/category.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatRadioModule } from '@angular/material/radio';
import { MapsDialogModule } from './maps-dialog/maps-dialog.module';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { CardListComponent } from './card-list/card-list.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { AlertComponent } from './alert/alert.component';
import { NotificationComponent } from './chat/notification/notification.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    LoadingComponent,
    DialogComponent,
    ActionButtonsComponent,
    CategoryComponent,
    ToolbarComponent,
    CardListComponent,
    SearchInputComponent,
    AlertComponent,
    NotificationComponent,
    NotificationDialogComponent,
    FormDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatTableModule,
    MatRadioModule,
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MaterialFileInputModule,
    FormsModule,
    MapsDialogModule,
    AngularEditorModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatTableModule,
    MatRadioModule,
    MatToolbarModule,
    MatCardModule,
    MaterialFileInputModule,
    LoadingComponent,
    ActionButtonsComponent,
    MapsDialogModule,
    ToolbarComponent,
    CardListComponent,
    SearchInputComponent,
  ],
})
export class ShareModule {}

<h1 mat-dialog-title>Nueva notificación global</h1>
<div mat-dialog-content class="accent">
  Envía una notificación simultanea a todos los colaboradores que ocupan la app.
</div>
<form [formGroup]="notificationForm" (ngSubmit)="onSave()">
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Titulo</mat-label>
    <input formControlName="title" matInput required>
    <mat-icon matSuffix>title</mat-icon>
  </mat-form-field>
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>Descripción</mat-label>
    <input formControlName="description" matInput required>
    <mat-icon matSuffix>title</mat-icon>
  </mat-form-field>

  <div mat-dialog-content class="mt-3">
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">Cancelar
      <mat-icon>close</mat-icon>
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="notificationForm.invalid">
      Enviar
      <mat-icon>send</mat-icon>
    </button>
  </mat-dialog-actions>
</form>


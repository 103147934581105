import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Actions } from '../../models/actions';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent implements OnInit {
  @Input() saveDisabled = true;
  @Input() onlyDraft = false;
  @Input() cancelIf = false;
  @Input() deleteIf = false;
  @Output() cancelAction: EventEmitter<Actions> = new EventEmitter<Actions>();
  @Output() deleteAction: EventEmitter<Actions> = new EventEmitter<Actions>();
  @Output() draftAction: EventEmitter<Actions> = new EventEmitter<Actions>();
  @Output() saveAction: EventEmitter<Actions> = new EventEmitter<Actions>();
  cancel = Actions.Cancel;
  delete = Actions.Delete;
  draft = Actions.Draft;
  add = Actions.Add;

  constructor() {}

  ngOnInit(): void {}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../../environments/environment';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private users = environment.tables.users;
  private USER = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private fireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging
  ) {
    this.fireAuth.authState.subscribe((user) => {
      if (user) {
        this.USER.next(
          new User(user.uid, user.email, user.displayName, user.photoURL)
        );
      }
    });
  }

  get isAuthenticated(): Observable<boolean> {
    return this.fireAuth.authState.pipe(map((user) => !!user));
  }

  get userId(): Observable<string> {
    return this.USER.asObservable().pipe(
      map((user) => {
        if (user) {
          return user.uid;
        } else {
          return null;
        }
      })
    );
  }

  get userEmail(): Observable<string> {
    return this.USER.asObservable().pipe(
      map((user) => {
        if (user) {
          return user.email;
        } else {
          return null;
        }
      })
    );
  }

  get currentUser(): Observable<User> {
    return this.fireAuth.authState.pipe(
      map((user) => {
        return new User(user.uid, user.email, user.displayName, user.photoURL);
      })
    );
  }

  login(data: any): Promise<boolean | 'unauthorized'> {
    return this.fireAuth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return this.fireAuth
          .signInWithEmailAndPassword(data.email, data.password)
          .then(async (user) => {
            return this.processUser(user.user);
          });
      });
  }

  signInWithGoogle(): Promise<boolean | 'unauthorized'> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    return this.fireAuth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return this.fireAuth.signInWithPopup(provider).then((resp) => {
          return this.processUser(resp.user);
        });
      });
  }

  private async processUser(user): Promise<boolean | 'unauthorized'> {
    const userData = user;
    const userSnap = this.firestore.collection(this.users).doc(userData.email);
    const cUser = await userSnap.get().toPromise();
    if (cUser.exists) {
      const cUserData = cUser.data();
      if (!cUserData['role'] || cUserData['role'] !== 'admin') {
        this.logout();
        return 'unauthorized';
      }
      this.updateUser(userSnap, userData.photoURL);
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  updateUser(userSnap, avatar): void {
    this.angularFireMessaging.requestToken.subscribe((pushToken) => {
      userSnap.update({ pushToken, avatar }).then(() => {});
    });
  }

  logout(): void {
    this.fireAuth
      .signOut()
      .then(() => {
        this.USER.next(null);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() title: string;
  @Input() secondTitle: string;
  @Input() adding: boolean;
  @Input() button: boolean;
  @Input() buttonText = 'Agregar';
  @Output() onAdding: EventEmitter<boolean> = new EventEmitter();
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onNavigate(path: string): void {
    this.router.navigateByUrl(path).then();
  }
}

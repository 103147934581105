<h1 mat-dialog-title>¿Desea guardar y enviar notificación?</h1>
<div class="accent" mat-dialog-content>
  Esto enviará una notifiación push a los usuarios para notificarles de este cambio.
</div>
<mat-dialog-actions align="end">
  <button mat-button color="warn" [mat-dialog-close]="action.Cancel">Cancelar <mat-icon>close</mat-icon></button>
  <button mat-button color="primary" [mat-dialog-close]="action.Save">Solo guardar <mat-icon>save</mat-icon></button>
  <button mat-raised-button color="primary" [mat-dialog-close]="action.Send">
    Si
    <mat-icon>send</mat-icon>
  </button>
</mat-dialog-actions>
